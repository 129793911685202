<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getWjk,
    editWjk,
    deleteWjk
  } from "@/api/admin/exam/createExamku.js"
  import {
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  import changeExam from '@/components/change-exam.vue'
  /**
   * 违纪库
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      changeExam


    },
    data() {
      return {
        title: "考试报名违纪库  /",
        title2: "2021年二级造价工程师职业资格增报专业考试违纪库",
        subTitle: "切换报名",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "违纪库",
            active: true
          }
        ],
        defaultvalue: 1,
        currentpage: 1,
        years: [],
        tableList: [],
        exam: [],
        ksbmbh: "",
        ksmc: "",
        ksnf: "",
        showmodal: false,
        addForm: {},
        exportTitle: "考试违纪库导出",
        importTitle: "考试违纪库导入",
        module: "WJK",
        select: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          keyword: "",
          ksnf: "",
          keyParam: "zjhm"
        }
      };
    },
    methods: {
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      check(file) {
        var _this = this;
        var fileExt = file.name.split(".")[file.name.split(".").length - 1];
        var sclx = file.name.indexOf("." + fileExt)
        if (sclx == -1) {
          _this.$notify.warning({
            message: "上传文件类型错误"
          });
          return false;
        } else {
          return true;
        }

      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      // 获取违纪库
      getList() {
        getWjk({
          ...this.pageData,
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 上传成功后调用
      uploadSuccess() {
        this.getList()
      },
      // 违纪库上传
      uploadWjk() {
        var _this = this;
        _this.$importModal().show({
          title: this.importTitle,
          module: this.module,
          ksbmbh: this.ksbmbh,
          success: this.uploadSuccess
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.addForm.ksbmbh = this.ksbmbh
        this.dialogTitle = "修改考试类别"
      },
      editWjk() {
        let formDate = this.addForm
        formDate.sid = this.addForm.sid
        editWjk(formDate).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: '数据操作成功!',
              duration: 3000,
            })
            this.showmodal = false
            this.getList()
          }
        })
      },
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.xm}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteWjk({
            ksbmbh: obj.ksbmbh ? obj.ksbmbh : this.ksbmbh,
            sids: obj.sid
          }).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //获取切换的考试
      getLastExam(params) {
        if (params) {
          this.getList({
            ksbmbh: this.ksbmbh,
          })
        } else {
          getNowExam().then(res => {
            if (res.status) {
              if (res.data) {
                this.ksbmbh = res.data.ksbmbh
                this.ksmc = res.data.ksmc
                this.pageData.ksnf = res.data.ksnf
              }
            }
            this.getList({
              ksbmbh: this.ksbmbh,
            })
          })

        }
      }
    },
    mounted() {
      this.ksnf = new Date().getFullYear();
      this.getYear()
      this.ksbmbh = this.$route.query.ksbmbh ? this.$route.query.ksbmbh : ''
      this.ksmc = this.$route.query.ksmc ? this.$route.query.ksmc : ''
      this.getLastExam(this.ksbmbh)
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <input placeholder="输入证件号查询" v-model="pageData.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30  " @click="uploadWjk"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>导入违纪库</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle,type: 'xlsx', module:module, ksbmbh: ksbmbh,condition:pageData})">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle,type: 'dbf', module:module, ksbmbh: ksbmbh,condition:pageData})">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th style="width: 10%;">证件号</th>
                    <th style="width:8%">姓名</th>
                    <th style="width:3%">性别 </th>
                    <th>违纪原因 </th>
                    <th>工作单位 </th>
                    <th style="width:8%">违纪有效年限 </th>
                    <th style="width:7%">开始时间 </th>
                    <th style="width:7%">结束时间 </th>
                    <th style="width: 5%;">操作 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td> {{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.zjhm}} </td>
                    <td>
                      <b-button variant="link" class="font-blue h30">{{obj.xm}}</b-button>
                    </td>
                    <td>{{obj.xb}}</td>
                    <td>{{obj.wjyy}}</td>
                    <td>{{obj.gzdw}}</td>
                    <td class="text-center">{{obj.wjyxnx}}</td>
                    <td>{{obj.kssj}}</td>
                    <td>{{obj.jssj}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two " @click="editItem(obj)"></i> <i
                        class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj)"></i>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <changeExam></changeExam>
    <b-modal id="addClass" v-model="showmodal" centered title="考试违纪库信息编辑" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">证件号：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" disabled v-model="addForm.zjhm"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">姓名：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" placeholder="姓名" v-model="addForm.xm"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="w-15">性别：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.xb"
                    value="男" class="form-check-input"><label for="exampleRadios1" class="form-check-label">男
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.xb"
                    value="女" class="form-check-input"><label for="exampleRadio1" class="form-check-label">女</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>违纪年限：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" v-model="addForm.wjyxnx" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>违纪原因：</label>
              <div class="col-sm-10 p-0 flexList">
                <textarea rows="3" cols=" " class="form-control w-100" v-model="addForm.wjyy"></textarea>

              </div>
            </div>
          </el-form-item>

        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="editWjk">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
